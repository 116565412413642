<!-- 空白页盖印预警详情 -->
<template>
    <section v-if="current?.businessType == 5">
        <a-descriptions title="" layout="vertical" bordered :column="2" size="small" class="descript"
            style="border-top: 1px solid #f0f0f0">
            <a-descriptions-item label="印章名称">{{ current?.sealName || '-' }}</a-descriptions-item>
            <a-descriptions-item label="印章保管员">{{ current?.custodianName || '-' }}</a-descriptions-item>
            <a-descriptions-item label="流程主题">
                <a v-if="current?.status != 1" class="detail-link" @click.prevent="toDetail(current?.documentId)">{{
                    current?.fileName || '-'
                }}</a>
                <span v-else>{{ current?.fileName || '-' }}</span>
            </a-descriptions-item>
            <a-descriptions-item label="预警时间">{{ current?.warningTime || '-' }}</a-descriptions-item>
            <a-descriptions-item label="申请外带地点">{{ current?.applicationAddress || '-' }}</a-descriptions-item>
            <a-descriptions-item label="实际盖印地点">{{ current?.practicalAddress || '-' }}</a-descriptions-item>
            <a-descriptions-item label="预警类型">{{
                warningStatusObj[current?.businessType].name || '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="预警信息">{{ current?.content || '-' }}</a-descriptions-item>
        </a-descriptions>
        <a-descriptions title="" layout="vertical" bordered :column="1" size="small" class="descript"
            style="border-top: 1px solid #f0f0f0">
            <a-descriptions-item label="用印影像">
                <!-- 用印影像 -->
                <section class="img-collection" v-if="current.documentFileRelVO.length">
                    <div class="seal-imgs" v-for="(item, index) in current.documentFileRelVO" :key="item.fullFile">
                        <!-- 影像开启 -->
                        <div v-if="!item.pictureType">
                            <!-- 影像抓拍成功 -->
                            <div v-if="item.photoType === 1" class="image-box">
                                <img v-if="item.fullFile" :src="item.fullFile + '?uploadFile=88x88'"
                                    @error="reImg($event, item.fullFile, 'span')" :data-imageType="item.imageType"
                                    class="seal-img" v-viewer="printImgOptions" :images="current.imgList" />
                                <!-- 补拍图片 -->
                                <i v-if="item.imageType != 1" :style="{
                                    display: 'block',
                                    position: 'absolute',
                                    top: '-1px',
                                    right: '-1px',
                                    width: '37px',
                                    height: '34px',
                                    background: `url(${cornerMark[item.imageType]})`,
                                    backgroundSize: '100%'
                                }"></i>
                                <!-- 同步中 -->
                                <div class="video-synchronization" v-if="!item.fullFile">
                                    <img src="@/assets/images/video-synchronization.png" />
                                </div>
                            </div>
                            <!-- 抓拍失败 -->
                            <a-tooltip title="由于盖印速度过快，图片抓取失败" overlayClassName="tooltip-custom" color="white"
                                v-if="item.photoType === 2">
                                <div class="grab-failure-img">
                                    <img src="@/assets/images/grab-failure.png" />
                                </div>
                            </a-tooltip>
                        </div>
                    </div>
                </section>
                <span v-else>无</span>
                <!-- </div> -->
            </a-descriptions-item>
        </a-descriptions>
    </section>
</template>
<script setup>
import { defineProps } from 'vue'
import { warningStatusObj } from '@/utils/constData'

const props = defineProps({
    current: {
        type: Object,
        default: () => ({})
    },
    toDetail: {
        type: Function
    },
    printImgOptions: {
        type: Object
    },
    cornerMark: {
        type: Object
    },
    reImg: {
        type: Function
    }

})

</script>

<style lang="less" scoped>
@import url('./shareStyle.less');
</style>
<style lang="less">
.detail-link,
.detail-link:hover {
    color: #1890ff;
}
</style>